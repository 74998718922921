<template>
    <div class="wrap-dashboard">
        <div class="content">
            <div class="summary-data">
                <div>Total Balance</div>
                <div class="nominal">IDR {{ formatIDR(totalBalance.total_balance) }}</div>
                <div>
                    <small>Last Update: {{ totalBalance.last_updated | formatDateTimeReal }}</small>
                </div>
            </div>
            <div class="d-flex justify-content-between mb-3">
                <div class="filter-sort">
                    <div class="filter">
                        <dropdown-directive position="left" top="2.5rem">
                            <template v-slot:buttonDropdown>
                                <button class="btn btn-filter">
                                    Filter By <span class="badge" v-if="countBadge != 0">{{ countBadge }}</span>
                                </button>
                            </template>
                            <template v-slot:menuDropdown>
                                <div>
                                    <div class="mt-3 ml-3 refresh-button" @click="resetFilter" v-if="paramsUrl.bank.length != 0 || paramsUrl.broker.length != 0">
                                        <img src="@/assets/icon_cms/refresh-circle.svg" alt="" />
                                        Reset Filter
                                    </div>
                                    <div class="wrap-filter-by">
                                        <div class="filter-by">
                                            <label for="">Bank</label>
                                            <dropdown-directive position="left" top="2.5rem" width="15rem">
                                                <template v-slot:buttonDropdown>
                                                    <div class="select-filter">
                                                        <div class="wrap-select-item">
                                                            <div v-if="paramsUrl.bank.length == 0">Select Bank</div>
                                                            <div v-else v-for="bank in paramsUrl.bank" :key="bank.bank_code" class="select-item">
                                                                {{ bank.bank_name }}
                                                                <!-- <img src="@/assets/icon_cms/close.svg" alt="close" class="select-close" /> -->
                                                            </div>
                                                        </div>
                                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                                    </div>
                                                </template>
                                                <template v-slot:menuDropdown>
                                                    <ul class="p-2">
                                                        <li v-for="(dataBank, i) in dataListBank" :key="i">
                                                            <input
                                                                type="checkbox"
                                                                name="bank"
                                                                :value="dataBank"
                                                                v-model="paramsUrl.bank"
                                                                id="bank"
                                                                @change="filterByBank"
                                                            />
                                                            <label for="bank" class="ml-2">{{ dataBank.bank_name }}</label>
                                                        </li>
                                                    </ul>
                                                </template>
                                            </dropdown-directive>
                                        </div>
                                        <div class="filter-by">
                                            <label for="">Broker</label>
                                            <dropdown-directive position="left" top="2.5rem" width="14rem">
                                                <template v-slot:buttonDropdown>
                                                    <div class="select-filter">
                                                        <div class="wrap-select-item">
                                                            <div v-if="paramsUrl.broker.length == 0">Select Bank</div>
                                                            <div v-else v-for="broker in paramsUrl.broker" :key="broker.broker_code" class="select-item">
                                                                {{ broker.broker_name }}
                                                                <!-- <img src="@/assets/icon_cms/close.svg" alt="close" class="select-close" /> -->
                                                            </div>
                                                        </div>
                                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                                    </div>
                                                </template>
                                                <template v-slot:menuDropdown>
                                                    <div class="p-2">
                                                        <ul>
                                                            <li v-for="(dataBroker, i) in dataListBroker" :key="i">
                                                                <input
                                                                    type="checkbox"
                                                                    name="broker"
                                                                    id="broker"
                                                                    :value="dataBroker"
                                                                    v-model="paramsUrl.broker"
                                                                    @change="filterByBroker"
                                                                />
                                                                <label for="broker" class="ml-2">{{ dataBroker.broker_name }}</label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </template>
                                            </dropdown-directive>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </dropdown-directive>
                    </div>
                    <div class="filter sort">
                        <dropdown-directive position="left" top="2.5rem">
                            <template v-slot:buttonDropdown><button class="btn btn-filter">Sort By</button></template>
                            <template v-slot:menuDropdown>
                                <div class="wrap-sort">
                                    <div class="sort-asc-dsc">
                                        <div class="form-group">
                                            <input type="radio" name="asc-desc" id="asc" value="asc" v-model="paramsUrl.order" @change="filterOrder" />
                                            <label for="asc">Ascending</label>
                                        </div>
                                        <div class="form-group">
                                            <input type="radio" name="asc-desc" id="dsc" value="desc" v-model="paramsUrl.order" @change="filterOrder" />
                                            <label for="dsc">Descending</label>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="form-group sort-by">
                                            <label for="">Sort By</label>
                                            <select name="" id="" v-model="paramsUrl.sortBy" @change="filterSortBy">
                                                <option value="" selected disabled>Select Field</option>
                                                <option value="created_at">Registration Date</option>
                                                <option value="hin">HIN</option>
                                                <option value="fullname">Fullname</option>
                                            </select>
                                            <img
                                                src="@/assets/icon_cms/close.svg"
                                                alt="close"
                                                class="icon-close"
                                                @click="resetSortBy"
                                                v-if="paramsUrl.sortBy != ''"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </dropdown-directive>
                    </div>
                </div>
                <div class="filter-search">
                    <input type="text" class="form-input" placeholder="input keyword" v-model="paramsUrl.keyword" @keyup="filterSearch" />
                    <img src="@/assets/icon_cms/search.svg" alt="" class="icon-input" />
                </div>
            </div>

            <div class="view-perpages">
                <select v-model="paramsUrl.perPage" @change="showDataRow">
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
                <span>&nbsp;entries per page</span>
            </div>
            <table>
                <thead>
                    <tr id="top">
                        <th scope="col">No</th>
                        <th scope="col">Broker</th>
                        <th scope="col">Name</th>
                        <th scope="col">HIN</th>
                        <th scope="col">Gold Balance (gr)</th>
                        <th scope="col">Bank</th>
                        <th scope="col">RPED</th>
                        <th scope="col">Balance</th>
                    </tr>
                </thead>
                <tbody v-if="isloadData">
                    <tr>
                        <td colspan="10">
                            <b-spinner label="Loading..."></b-spinner>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr v-for="(data, i) in userDatas" :key="i" v-show="userDatas.length != 0">
                        <td>{{ (paramsUrl.currentPage - 1) * paramsUrl.perPage + (i + 1) }}</td>
                        <td>{{ data.broker_code == "BEI03002" || data.broker_code == "BEI03001" ? "ABI Commodity Futures" : "" }}</td>
                        <td>{{ formatCapitalize(data.fullname) }}</td>
                        <td>{{ data.trade_account.hin }}</td>
                        <td>
                            <b-spinner v-if="dataGram[i] == 'loading'" small></b-spinner>
                            <span v-else>{{ dataGram[i] }}</span>
                        </td>
                        <td>{{ data.bank_account[0].name }}</td>
                        <td>{{ data.bank_account[0].account_no }}</td>
                        <td>
                            <b-spinner v-if="dataBalance[i] == 'loading'" small></b-spinner>
                            <span v-else>{{ "IDR " + dataBalance[i] }}</span>
                        </td>
                    </tr>
                    <tr v-show="userDatas.length == 0">
                        <td colspan="10">No Data Available</td>
                    </tr>
                </tbody>
            </table>
            <div class="d-flex justify-content-end mt-3">
                <b-pagination
                    v-model="paramsUrl.currentPage"
                    :total-rows="totalData"
                    :per-page="paramsUrl.perPage"
                    @change="handlePageChange"
                    aria-controls="new-table"
                    first-number
                    last-number
                    v-show="totalData > paramsUrl.perPage"
                ></b-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import DropdownDirective from "../../components/dropdown/DropdownDirective.vue";
export default {
    components: { DropdownDirective },
    data() {
        return {
            loadingData: false,
            today: "",
            form: {
                startDate: "",
                endDate: "",
            },
            revenue: "",
            imgRevenue: null,
            role: "",
            displayCategory: false,
            selectedId: null,
            dataBalance: [],
            dataGram: [],
            // totalData: null,
            isLoaderBalance: false,
            paramsUrl: {
                currentPage: 1,
                perPage: 10,
                keyWord: "",
                broker: [],
                bank: [],
                sortBy: "",
                order: "",
            },
            dataListBank: "",
            dataListBroker: "",
            timeOut: setTimeout(() => {}, 0),
            bagNominal: [],
            totalBalance: "",
        };
    },
    mounted() {
        this.$store.dispatch("getTradeAccount", this.paramsUrl).then((res) => {
            for (let i = 0; i < res.length; i++) {
                this.$set(this.dataGram, i, "loading");
                this.$set(this.dataBalance, i, "loading");

                axios.get("/v2/clearing/admin/get/total_gold?account_id=" + res[i].trade_account.account_id).then((resGram) => {
                    let totalGram = resGram.data.data.body.total_gram;
                    this.$set(this.dataGram, i, this.formatIDR(totalGram));
                });

                axios
                    .get("/v2/clearing/admin/get/account_balance?account_no=" + res[i].bank_account[0].account_no)
                    .then((resbalance) => {
                        let balance = resbalance.data.data.body.Balance;
                        this.$set(this.dataBalance, i, this.formatIDR(balance));
                    })
                    .catch(() => {
                        this.$set(this.dataBalance, i, "");
                    });
            }
        });

        axios.get("v2/attributes/bank_list").then((res) => {
            this.dataListBank = res.data.data.body;
        });
        axios.get("v2/attributes/broker_list").then((res) => {
            this.dataListBroker = res.data.data.body;
        });

        axios.get("v2/clearing/admin/get/total_balance").then((res) => {
            this.totalBalance = res.data.data.body;
        });
        // this.loadingData = true;
        // axios.get("/v2/clearing/admin/get/trade_account?offset=" + this.currentPage + "&limit=" + this.perPage).then((res) => {
        //     this.dataUsers = res.data.data.body;
        //     this.totalData = res.data.data.count;
        //     this.isLoaderBalance = true;
        //     for (let i = 0; i < this.dataUsers.length; i++) {
        //         axios.get("/v2/clearing/admin/get/account_balance?account_no=" + this.dataUsers[i].bank_account[0].account_no).then((resBalance) => {
        //             this.isLoaderBalance = true;
        //             this.$set(this.dataBalance, i, resBalance.data.data.body.Balance);
        //             this.dataFixUser[i] = {
        //                 name: this.dataUsers[i].fullname,
        //                 hin: this.dataUsers[i].trade_account.hin,
        //                 bank_name: this.dataUsers[i].bank_account[0].name,
        //                 rped: this.dataUsers[i].bank_account[0].account_no,
        //                 balance: resBalance.data.data.body.Balance,
        //             };
        //             this.isLoaderBalance = false;
        //         });
        //     }
        //     this.loadingData = false;
        // });
    },
    methods: {
        formatCapitalize(value) {
            let string = value.toLowerCase();
            return string.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
        },
        filterByBank() {
            this.$store.dispatch("getTradeAccount", this.paramsUrl).then((res) => {
                for (let i = 0; i < res.length; i++) {
                    this.$set(this.dataGram, i, "loading");
                    this.$set(this.dataBalance, i, "loading");

                    axios.get("/v2/clearing/admin/get/total_gold?account_id=" + res[i].trade_account.account_id).then((resGram) => {
                        let totalGram = resGram.data.data.body.total_gram;
                        this.$set(this.dataGram, i, this.formatIDR(totalGram));
                    });

                    axios.get("/v2/clearing/admin/get/account_balance?account_no=" + res[i].bank_account[0].account_no).then((resbalance) => {
                        let balance = resbalance.data.data.body.Balance;
                        this.$set(this.dataBalance, i, this.formatIDR(balance));
                    });
                }
            });
        },
        filterByBroker() {
            this.$store.dispatch("getTradeAccount", this.paramsUrl).then((res) => {
                for (let i = 0; i < res.length; i++) {
                    this.$set(this.dataGram, i, "loading");
                    this.$set(this.dataBalance, i, "loading");

                    axios.get("/v2/clearing/admin/get/total_gold?account_id=" + res[i].trade_account.account_id).then((resGram) => {
                        let totalGram = resGram.data.data.body.total_gram;
                        this.$set(this.dataGram, i, this.formatIDR(totalGram));
                    });

                    axios.get("/v2/clearing/admin/get/account_balance?account_no=" + res[i].bank_account[0].account_no).then((resbalance) => {
                        let balance = resbalance.data.data.body.Balance;
                        this.$set(this.dataBalance, i, this.formatIDR(balance));
                    });
                }
            });
        },
        filterOrder() {
            this.$store.dispatch("getTradeAccount", this.paramsUrl).then((res) => {
                for (let i = 0; i < res.length; i++) {
                    this.$set(this.dataGram, i, "loading");
                    this.$set(this.dataBalance, i, "loading");

                    axios.get("/v2/clearing/admin/get/total_gold?account_id=" + res[i].trade_account.account_id).then((resGram) => {
                        let totalGram = resGram.data.data.body.total_gram;
                        this.$set(this.dataGram, i, this.formatIDR(totalGram));
                    });

                    axios.get("/v2/clearing/admin/get/account_balance?account_no=" + res[i].bank_account[0].account_no).then((resbalance) => {
                        let balance = resbalance.data.data.body.Balance;
                        this.$set(this.dataBalance, i, this.formatIDR(balance));
                    });
                }
            });
        },
        filterSortBy() {
            this.$store.dispatch("getTradeAccount", this.paramsUrl).then((res) => {
                for (let i = 0; i < res.length; i++) {
                    this.$set(this.dataGram, i, "loading");
                    this.$set(this.dataBalance, i, "loading");

                    axios.get("/v2/clearing/admin/get/total_gold?account_id=" + res[i].trade_account.account_id).then((resGram) => {
                        let totalGram = resGram.data.data.body.total_gram;
                        this.$set(this.dataGram, i, this.formatIDR(totalGram));
                    });

                    axios.get("/v2/clearing/admin/get/account_balance?account_no=" + res[i].bank_account[0].account_no).then((resbalance) => {
                        let balance = resbalance.data.data.body.Balance;
                        this.$set(this.dataBalance, i, this.formatIDR(balance));
                    });
                }
            });
        },
        filterSearch(event) {
            this.paramsUrl.keyWord = event.target.value;
            clearTimeout(this.timeOut);
            this.timeOut = setTimeout(() => {
                this.$store.dispatch("getTradeAccount", this.paramsUrl).then((res) => {
                    for (let i = 0; i < res.length; i++) {
                        this.$set(this.dataGram, i, "loading");
                        this.$set(this.dataBalance, i, "loading");

                        axios.get("/v2/clearing/admin/get/total_gold?account_id=" + res[i].trade_account.account_id).then((resGram) => {
                            let totalGram = resGram.data.data.body.total_gram;
                            this.$set(this.dataGram, i, this.formatIDR(totalGram));
                        });

                        axios.get("/v2/clearing/admin/get/account_balance?account_no=" + res[i].bank_account[0].account_no).then((resbalance) => {
                            let balance = resbalance.data.data.body.Balance;
                            this.$set(this.dataBalance, i, this.formatIDR(balance));
                        });
                    }
                });
            }, 700);
        },
        resetFilter() {
            this.paramsUrl.bank = [];
            this.paramsUrl.broker = [];
            this.$store.dispatch("getTradeAccount", this.paramsUrl).then((res) => {
                for (let i = 0; i < res.length; i++) {
                    this.$set(this.dataGram, i, "loading");
                    this.$set(this.dataBalance, i, "loading");

                    axios.get("/v2/clearing/admin/get/total_gold?account_id=" + res[i].trade_account.account_id).then((resGram) => {
                        let totalGram = resGram.data.data.body.total_gram;
                        this.$set(this.dataGram, i, this.formatIDR(totalGram));
                    });

                    axios.get("/v2/clearing/admin/get/account_balance?account_no=" + res[i].bank_account[0].account_no).then((resbalance) => {
                        let balance = resbalance.data.data.body.Balance;
                        this.$set(this.dataBalance, i, this.formatIDR(balance));
                    });
                }
            });
        },
        resetSortBy() {
            this.paramsUrl.sortBy = "";
            this.$store.dispatch("getTradeAccount", this.paramsUrl).then((res) => {
                for (let i = 0; i < res.length; i++) {
                    this.$set(this.dataGram, i, "loading");
                    this.$set(this.dataBalance, i, "loading");

                    axios.get("/v2/clearing/admin/get/total_gold?account_id=" + res[i].trade_account.account_id).then((resGram) => {
                        let totalGram = resGram.data.data.body.total_gram;
                        this.$set(this.dataGram, i, this.formatIDR(totalGram));
                    });

                    axios.get("/v2/clearing/admin/get/account_balance?account_no=" + res[i].bank_account[0].account_no).then((resbalance) => {
                        let balance = resbalance.data.data.body.Balance;
                        this.$set(this.dataBalance, i, this.formatIDR(balance));
                    });
                }
            });
        },
        handlePageChange(value) {
            this.paramsUrl.currentPage = value;
            this.$store.dispatch("getTradeAccount", this.paramsUrl).then((res) => {
                for (let i = 0; i < res.length; i++) {
                    this.$set(this.dataGram, i, "loading");
                    this.$set(this.dataBalance, i, "loading");

                    axios.get("/v2/clearing/admin/get/total_gold?account_id=" + res[i].trade_account.account_id).then((resGram) => {
                        let totalGram = resGram.data.data.body.total_gram;
                        this.$set(this.dataGram, i, this.formatIDR(totalGram));
                    });

                    axios.get("/v2/clearing/admin/get/account_balance?account_no=" + res[i].bank_account[0].account_no).then((resbalance) => {
                        let balance = resbalance.data.data.body.Balance;
                        this.$set(this.dataBalance, i, this.formatIDR(balance));
                    });
                }
            });
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        showDataRow() {
            this.currentPage = 1;
            this.$store.dispatch("getTradeAccount", this.paramsUrl).then((res) => {
                for (let i = 0; i < res.length; i++) {
                    this.$set(this.dataGram, i, "loading");
                    this.$set(this.dataBalance, i, "loading");

                    axios.get("/v2/clearing/admin/get/total_gold?account_id=" + res[i].trade_account.account_id).then((resGram) => {
                        let totalGram = resGram.data.data.body.total_gram;
                        this.$set(this.dataGram, i, this.formatIDR(totalGram));
                    });

                    axios.get("/v2/clearing/admin/get/account_balance?account_no=" + res[i].bank_account[0].account_no).then((resbalance) => {
                        let balance = resbalance.data.data.body.Balance;
                        this.$set(this.dataBalance, i, this.formatIDR(balance));
                    });
                }
            });
        },
        formatPrice(value) {
            let val = value / 1;
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        formatIDR(value) {
            let val = (value / 1).toFixed(2).replace(",", ".");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        select(itemId) {
            this.selectedId = itemId;
            this.displayCategory = !this.displayCategory;
        },
        selectedDropdown(value, img) {
            this.revenue = value;
            this.imgRevenue = img;
        },
    },
    computed: {
        userDatas() {
            // return this.$store.state.cashModule.tradeAccountData;
            return this.$store.getters.getDataTrade;
        },
        totalData() {
            return this.$store.state.cashModule.totalTradeAccountData;
        },
        isloadData() {
            return this.$store.state.cashModule.isLoadingData;
        },

        countBadge() {
            let nominal = this.paramsUrl.bank.length + this.paramsUrl.broker.length;
            return nominal;
        },
    },
};
</script>

<style scoped>
/*.wrap-dashboard {
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
}*/

.summary-data {
    /* box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.2); */
    transition: 0.3s;
    padding: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    min-width: 20%;
    max-width: 30%;
    border: solid 1px #dddddd;
    margin-bottom: 1rem;
}

.summary-data .nominal {
    font-size: 2rem;
    font-weight: bold;
}

.summary-data:hover {
    /* box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2); */
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.2);
}

.filter-sort {
    display: flex;
    gap: 1rem;
}

.filter-search {
    width: 25%;
    position: relative;
}

.icon-input {
    position: absolute;
    top: 0.65rem;
    right: 0.65rem;
}

.select-filter {
    border: solid 1px #ddd;
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    font-size: 13px;
    width: 10rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: auto;
}

/* width */
.select-filter::-webkit-scrollbar {
    height: 3px;
}

/* Track */
.select-filter::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.select-filter::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.select-filter::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.select-filter i {
    font-size: 1.15rem;
}

.wrap-select-item {
    display: flex;
    gap: 0.5rem;
}

.select-item {
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 1px 0.5rem;
    white-space: nowrap;
    width: 7rem;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 11px;
    position: relative;
    transition: width 1s;
}

.select-item:hover {
    position: absolute;
    top: 9px;
    background-color: #ffff;
    width: 11.5rem;
    min-width: 11.5rem;
    z-index: 1;
    transition: width 1s;
}

.select-close {
    position: absolute;
    right: 0.5rem;
    top: 0.3rem;
    width: 0.5rem;
    cursor: pointer;
}

.refresh-button {
    cursor: pointer;
    color: #2e75b6;
    width: 26%;
}

.btn-filter {
    box-shadow: unset;
    background-color: var(--primary-color);
    color: #ffffff;
    font-size: 14px;
    border-radius: 5px;
}

.wrap-filter-by {
    padding: 1rem;
    display: flex;
    gap: 1rem;
}

.filter-by label:first-child {
    font-weight: bold;
}

.filter-by ul {
    margin: unset;
}

.wrap-sort {
    padding: 1rem;
    width: 16rem;
}

.badge {
    width: 1rem;
    height: 1rem;
    background: #fff;
    color: #2e75b6;
    border-radius: 50%;
    font-size: 0.69rem;
}

.sort-asc-dsc {
    display: flex;
    justify-content: space-around;
    border-bottom: solid 1px #dddddd;
    margin-bottom: 1rem;
}

.icon-close {
    position: absolute;
    right: 1.5rem;
    top: 2.25rem;
    width: 0.75rem;
    cursor: pointer;
}

input[type="radio"] {
    margin-right: 0.5rem;
}
.sort-by label {
    font-weight: bold;
    display: block;
}

.sort-by select {
    width: 100%;
}

.top-title {
    display: flex;
    justify-content: space-between;
}

.bagi {
    height: 30px;
}

.sort-date {
    display: flex;
    gap: 1rem;
}

.form-group {
    margin-bottom: unset;
    position: relative;
}

.form-input {
    border: solid 1px #ccc;
    height: 2.5rem;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    width: 100%;
}

.form-input::placeholder {
    color: #ccc;
}

/* .bagi-menu {
  background-image: url("../assets/logo_agi.png");
} */

select#revenue option[value="bank"] {
    background-image: url("../../assets/logo_agi.png");
}

.title-page {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 40px;
}

.option-table {
    background: #f8f8f8;
    border: none;
    width: 100%;
}

.icon-trade {
    width: 25px;
}

.footer {
    background: var(--primary-color);
    color: white;
    padding: 20px 47px;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    height: 62px;
}

.wrap-input {
    margin-bottom: 10px;
}
.label-input {
    width: 108px;
}

select {
    border-radius: 3px;
    border-color: #a5a5a5a5;
    padding: 3px;
}

.card {
    width: 314px;
    font-size: 14px;
    border-radius: 10px;
}

.card-header {
    padding: 1rem;
}

.buyer,
.seller,
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 12px;
    position: relative;
    left: 20px;
}

.title::before {
    content: "";
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: var(--scondary-color);
    left: -17px;
    top: 3px;
}

.buyer {
    margin-bottom: 20px;
}

.content-right {
    text-align: right;
}

.amount {
    font-weight: bold;
}

.btn-primer {
    background: var(--scondary-color);
    border: none;
    color: #ffffff;
    padding: 5px 25px;
    border-radius: 5px;
    cursor: pointer;
}

.btn-primer:hover {
    background-color: var(--scondary-color-hover);
}

.button-dropdown {
    width: 100% !important;
}

.dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.item-dropdown {
    cursor: pointer;
    font-size: 14px;
    text-align: left;
    display: flex;
    gap: 0.6em;
}

.item-dropdown:hover {
    background: #f2f2f2;
}

.btn-group > .btn {
    box-shadow: none;
}

.dropdown-menu {
    width: 100%;
    font-size: 13px;
    transform: translate3d(-30px, 21px, 0px);
}

.dropdown-menu.show {
    transform: translate3d(-30px, 21px, 0px) !important;
}

.dropdown-item {
    cursor: pointer;
    display: flex;
    gap: 0.5rem;
    padding: 0.25rem 0.7rem;
}

.img-revenue {
    height: 15px;
}

.wrap-img {
    width: 33px;
    text-align: center;
}

.wrap-img img {
    height: 15px;
}
</style>
